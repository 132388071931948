
import { defineComponent, ref } from 'vue'
import TopBar from '@/components/TopBar.vue'
import Footer from '@/components/Footer.vue'

export default defineComponent({
  name: 'Partners',
  components: {
    TopBar,
    Footer,
  },

  setup(){
    const menuTag = ref(1)
    return{
      menuTag
    }
  }
});
